import React from 'react';

function Privacy() {
  return (
    <div className="privacy-policy">
              <div className="container">

      <h1 className='title'>Política de privacidad</h1>
      <p><strong>Última actualización:</strong> 21 de Octubre de 2024</p>
      <p><strong>EuropaCambiosVE, S.L.</strong> (en adelante, “EuropaCambiosVE”)</p>
<section className="section">   
      <p>
        Nuestra prioridad está en salvaguardar la privacidad de la información personal de nuestros clientes,
        para nosotros es fundamental dar el uso correcto de la información personal. EuropaCambiosVE cumplirá
        con la normativa vigente en materia de protección de datos de carácter personal, en concreto el 
        Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la 
        protección de las personas físicas en lo que respecta al tratamiento de datos personales (RGPD) y la 
        Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos 
        digitales (LOPDGDD).
      </p>
      </section> 


      <p>
        En EuropaCambiosVE, su privacidad es importante para nosotros, para ello hemos desarrollado esta 
        política de privacidad para explicar cómo se recopila, clasifica, utiliza, protege y comparte sus 
        datos de carácter personal en el momento de hacer uso de nuestros servicios a través de nuestra 
        plataforma/sitio web. Quedando entendido que se consideran datos de carácter personal cualquier 
        información relativa a una persona física identificada o identificable. EuropaCambiosVE será 
        responsable del tratamiento de los datos de carácter personal que se recaben a través de la plataforma,
        bien sea en la fase de registro o durante el uso de la plataforma o sitio web. Igualmente será el 
        encargado del tratamiento de los datos de carácter personal que se recopilen a través de la plataforma 
        a los fines de ejecutar el servicio de envío de dinero.
      </p>

      <h2 className='section-title'>Datos recopilados</h2>
      <p>
        Recopilamos diferentes tipos de información de carácter personal al momento que usted interactúa con 
        nosotros, utilizando nuestros servicios y accediendo a nuestra plataforma o sitio web, dicha 
        información puede incluir lo siguiente:
      </p>
      <ul>
        <li><strong>Datos personales (identificación):</strong> como nombres y apellidos, fecha de nacimiento, sexo, imágenes, dirección postal, documento de identificación, nacionalidad, dirección de domicilio, dirección de correo electrónico y actividad profesional.</li>
        <li>
          <strong>Datos tecnológicos:</strong> como dirección IP, información del navegador y del dispositivo, 
          información recopilada a través de cookies.
        </li>
      </ul>

      <p>
        A los fines de cumplir con las Leyes y toda regulación que nos aplique (legislación contra lavado de 
        dinero y financiación de terrorismo). Podemos solicitar verificación de su identidad, con tecnología 
        biométrica video selfie que analice, sus imágenes faciales u otra información.
      </p>

      <h2 className='section-title'>Cumplimiento y retención de datos</h2>
      <p>
        EuropaCambiosVE se compromete a cumplir con las leyes de protección de datos y garantizar que la 
        información personal sea:
      </p>
      <ul>
        <li>Utilizada de forma lícita, justa y transparente;</li>
        <li>Recopilada para los fines válidos y no dar uso indebido;</li>
        <li>Actualizada;</li>
        <li>Cuando sea necesario para los fines comunicados guardada de forma segura.</li>
      </ul>

      <h2 className='section-title'>Cómo compartimos su información</h2>
      <p>
        Para cualquiera de los fines que se detallan en esta declaración de privacidad EuropaCambiosVE podrá 
        compartir y divulgar los datos personales con:
      </p>
      <ul>
        <li>
          Proveedores de servicios de pago y otros agentes o socios comerciales necesarios para nuestra 
          operativa, todos ellos teniendo la obligación mediante acuerdo a mantener la confidencialidad de los 
          datos personales y a darles el uso que corresponda.
        </li>
        <li>
          Procesadores de datos bajo relación contractual para proporcionar atención al cliente.
        </li>
        <li>
          Proveedores de servicios de marketing y publicidad bajo tu consentimiento cuando sea aplicable.
        </li>
        <li>
          Proveedores de servicios de verificación de identidad enfocados en gestionar los riesgos relacionados 
          con el fraude y la identidad.
        </li>
      </ul>

      <h2 className='section-title'>Retención de información personal</h2>
      <p>
        EuropaCambiosVE, retendrá tu información personal el tiempo necesario, para cumplir los fines para los 
        cuales fueron recopilados. Conservaremos su información personal con base a los periodos de retención 
        recogidos en la normativa aplicable y de ser necesario los conservaremos un tiempo más largo de existir 
        algún requerimiento o deber legal que nos obligue a que la información permanezca en nuestra base de 
        datos.
      </p>

      <h2 className='section-title'>Derechos sobre los datos personales del cliente</h2>
      <p>
        Tiene el derecho a acceder a su información personal, previo a una solicitud y según lo establecido en 
        las leyes. EuropaCambiosVE, de acuerdo con las políticas de protección de datos, dará respuesta a su 
        solicitud sin incurrir en dilación indebida. Queremos desde EuropaCambiosVE darles a conocer que tienen 
        los siguientes derechos de acceso a los datos:
      </p>
      <ul>
        <li>Acceso a su información personal.</li>
        <li>Acceso a rectificación de la información personal incorrecta.</li>
        <li>Acceso a eliminar información en algunas circunstancias.</li>
      </ul>

      <h2 className='section-title'>Canal de quejas en relación a la privacidad</h2>
      <p>
        Si cree que no hemos cumplido con nuestras obligaciones en virtud de la presente Política de Privacidad 
        o de la normativa en materia de protección de datos, tiene derecho a presentar una queja ante la 
        autoridad de Protección de Datos. Le aconsejamos que nos comunique cualquier reclamación relacionada con 
        la privacidad con nosotros, y responderemos de acuerdo con nuestro procedimiento de reclamaciones.
      </p>
      <p>
        Correo electrónico: <a href="mailto:Servicio@europacambiosve.com">Servicio@europacambiosve.com</a>
      </p>

      <h2 className='section-title'>Contacte con nosotros</h2>
      <p>
        Si tienes dudas sobre nuestra Política de Privacidad, póngase en contacto con nuestro responsable de 
        protección de datos: <a href="mailto:Servicio@europacambiosve.com">Servicio@europacambiosve.com</a>
      </p>
    </div>
    </div>
  );
}

export { Privacy };
