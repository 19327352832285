import React, { useState, useCallback, useEffect } from "react";
import { Button, Input, FormGroup, Label, Alert } from "reactstrap";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import {
  FaCheck,
  FaCheckCircle,
  FaDollarSign,
  FaEuroSign,
  FaArrowLeft,
  FaArrowRight,
  FaTimesCircle,
} from "react-icons/fa";
import { toast } from "react-toastify";
import { useAxiosInterceptors } from "../Hooks/useAxiosInterceptors";
import { useDataContext } from "../Context/dataContext";

function Recharge() {
  useAxiosInterceptors();
  const { infoTkn, url } = useDataContext();
  const [step, setStep] = useState(1);
  const [payment, setPayment] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("");
  const [bankOptionPay, setBankOptionPay] = useState("");
  const [sendAmount, setSendAmount] = useState("");
  const [cashPhone, setCashPhone] = useState("");
  const [cash, setCash] = useState("");
  const [mov_img, setMov_img] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [transactionError, setTransactionError] = useState(false); // Controla si hubo un problema
  const [transactionDone, setTransactionDone] = useState(false); // Controla si la transacción ya fue intentada

  // Datos de los bancos
  const [banksEUR, setBanksEUR] = useState([]);
  const [banksUSD, setBanksUSD] = useState([]);

  // Datos Usuario
  const [user, setUser] = useState([]);

  // Fetch de datos del usuario (Incluye movimientos y directorio)
  const fetchDataUser = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/Auth/findByToken/${infoTkn}`, {
        headers: {
          Authorization: `Bearer ${infoTkn}`,
        },
      });
      setUser(response.data);

      // setShowAlert(true);
    } catch (error) {
      console.log(error);
    }
  }, [setUser, infoTkn, url]);

  // Fetch de datos de los bancos en EUR
  const fetchDataAccEur = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/Acceur`, {
        headers: {
          Authorization: `Bearer ${infoTkn}`,
        },
      });
      setBanksEUR(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [url, infoTkn]);

  // Fetch de datos de los bancos en USD
  const fetchDataAccUsd = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/AccUsd`, {
        headers: {
          Authorization: `Bearer ${infoTkn}`,
        },
      });
      setBanksUSD(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [url, infoTkn]);

  //Enviar a espera una recarga
  const handleSubmitLoad = async (event) => {
    event.preventDefault();

    const findBankName = () => {
      if (payment === "USD") {
        const bank = banksUSD.find(
          (bank) => bank.accusd_id === parseInt(bankOptionPay)
        );
        if (bank) {
          return bank.accusd_Bank;
        }
      }
      if (payment === "EUR") {
        const bank = banksEUR.find(
          (bank) => bank.acceur_id === parseInt(bankOptionPay)
        );
        if (bank) {
          return bank.acceur_Bank;
        }
      }
    };

    const formData = new FormData();
    formData.append("mov_currency", payment);
    formData.append("mov_amount", sendAmount);
    formData.append("mov_oldAmount", 0);
    formData.append("mov_type", "Deposito");
    formData.append("mov_status", "E");
    formData.append("mov_comment", findBankName());
    formData.append("mov_code", cash);
    formData.append("mov_phone", cashPhone);
    formData.append("mov_img", mov_img);
    formData.append(
      "mov_accEurId",
      payment === "EUR" && selectedMethod === "efectivoBBVA"
        ? 1
        : payment === "EUR" && selectedMethod === "efectivoSantander"
        ? 2
        : payment === "EUR"
        ? parseInt(bankOptionPay)
        : 0
    );
    formData.append(
      "mov_accUsdId",
      payment === "USD" ? parseInt(bankOptionPay) : 0
    );
    formData.append("mov_userId", user.use_id);

    try {
      // Crear el movimiento
      const response = await axios.post(`${url}/Movements/create`, formData, {
        headers: {
          Authorization: `Bearer ${infoTkn}`,
          "Content-Type": "multipart/form-data",
        },
      });

      const movementId = response.data.mov_id; // Obtén el ID del movimiento recién creado
      
      fetchDataUser();
      handleNextStep();
      toast.success(
        "Cambio realizado con éxito!, En un momento se verá reflejado tu ingreso en la plataforma",
        {
          position: "bottom-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setTransactionDone(true);
      setTransactionError(false);
      console.log("Request sent successfully");
      handleNextStep();
      // Enviar correo después de la creación del movimiento
      axios.post(
        `${url}/Mailer/pendantIncome/joseportillo2002.jdpf@gmail.com/${movementId}`,
        {
          headers: {
            Authorization: `Bearer ${infoTkn}`,
          },
        }
      );
    } catch (error) {
      setTransactionDone(false);
      console.log(transactionDone);
      setTransactionError(true);
      console.log(error);
      toast.error("Error al procesar la recarga", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleCurrencyChange = (currency) => {
    setPayment(currency);
    setStep(2);
  };

  const handleMethodChange = (e) => {
    setSelectedMethod(e.target.value);
  };

  const handleBankChange = (e) => {
    setBankOptionPay(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setMov_img(file);
    setImageUrl(URL.createObjectURL(file));
  };

  const validateStep = () => {
    if (step === 2) {
      if (!payment) {
        setErrorMessage("Por favor, selecciona una moneda.");
        return false;
      }
      if (!selectedMethod) {
        setErrorMessage("Por favor, selecciona un método de recarga.");
        return false;
      }
      if (selectedMethod === "transferencia" && !bankOptionPay) {
        setErrorMessage("Por favor, selecciona un banco.");
        return false;
      }
      if (
        (selectedMethod === "efectivoBBVA" ||
          selectedMethod === "efectivoSantander") &&
        (!cashPhone || !cash)
      ) {
        setErrorMessage(
          "Por favor, completa todos los campos de Efectivo Móvil."
        );
        return false;
      }
      if (!sendAmount || sendAmount < 20) {
        setErrorMessage("El monto debe ser superior a 20.");
        return false;
      }
    }

    if (step === 3 && !mov_img) {
      setErrorMessage("Por favor, adjunta el comprobante de pago.");
      return false;
    }

    setErrorMessage(""); // Limpiar el mensaje de error si pasa todas las validaciones
    return true;
  };
  

  const handleNextStep = () => {
    if (validateStep()) {
      if (step < 4) {
        setStep(step + 1);
      }
    }
  };

  const handlePreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const resetRecharge = () => {
    setStep(1);
    setPayment("");
    setSelectedMethod("");
    setBankOptionPay("");
    setSendAmount("");
    setCash("");
    setCashPhone("");
    setMov_img(null);
    setErrorMessage("");
  };

  useEffect(() => {
    fetchDataAccEur();
    fetchDataAccUsd();
    fetchDataUser();
  }, [fetchDataAccEur, fetchDataAccUsd, fetchDataUser]);

  return (
    <div className="recharge-container">
      <h2 className="recharge-title">Recargar Saldo</h2>

      {/* Step Indicator */}
      <div className="step-indicator">
        {[1, 2, 3, 4].map((stepNumber) => (
          <div
            key={stepNumber}
            className={`step-item ${
              step >= stepNumber
                ? step > stepNumber
                  ? "completed"
                  : "active"
                : ""
            }`}
            onClick={() => step > stepNumber && setStep(stepNumber)}
          >
            {step > stepNumber ? (
              <FaCheck className="step-icon" />
            ) : (
              <div className="step-number">{stepNumber}</div>
            )}
            <p className="step-label">
              {
                ["Moneda", "Método", "Comprobante", "Confirmación"][
                  stepNumber - 1
                ]
              }
            </p>
          </div>
        ))}
      </div>

      {/* Mensaje de Error */}
      {errorMessage && (
        <Alert color="danger" className="error-alert">
          {errorMessage}
        </Alert>
      )}

      {/* Paso 1: Selección de moneda */}
      <CSSTransition
        in={step === 1}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <div className="step-content">
          <h3 className="step-header">Selecciona la moneda</h3>
          <div className="currency-options">
            <Button
              color="light"
              className={`currency-btn ${payment === "EUR" ? "selected" : ""}`}
              onClick={() => handleCurrencyChange("EUR")}
            >
              <FaEuroSign className="currency-icon" /> Euros (€)
            </Button>
            <Button
              color="light"
              className={`currency-btn ${payment === "USD" ? "selected" : ""}`}
              onClick={() => handleCurrencyChange("USD")}
            >
              <FaDollarSign className="currency-icon" /> Dólares ($)
            </Button>
          </div>
        </div>
      </CSSTransition>

      {/* Paso 2: Selección de método */}
      <CSSTransition
        in={step === 2}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <div className="step-content">
          <h3 className="step-header">Elige tu método de recarga</h3>
          <FormGroup>
            <Label for="methodSelect" className="form-label">
              Método de Recarga
            </Label>
            <Input
              type="select"
              id="methodSelect"
              value={selectedMethod}
              onChange={handleMethodChange}
              className="custom-select"
            >
              <option value="">Selecciona el método</option>
              {payment === "EUR" && (
                <>
                  <option value="transferencia">Transferencia Bancaria</option>
                  <option value="efectivoBBVA">Efectivo Móvil BBVA</option>
                  <option value="efectivoSantander">
                    Efectivo Por Cajero Santander
                  </option>
                </>
              )}
              {payment === "USD" && (
                <option value="transferencia">
                  Transferencia Bancaria - Bank of America
                </option>
              )}
            </Input>
          </FormGroup>

          {selectedMethod === "transferencia" && (
            <FormGroup>
              <Label for="bankSelect" className="form-label">
                Selecciona el Banco
              </Label>
              <Input
                type="select"
                id="bankSelect"
                value={bankOptionPay}
                onChange={handleBankChange}
                className="custom-select"
              >
                <option value="">Selecciona un banco</option>
                {payment === "EUR"
                  ? banksEUR
                      .filter((bank) => bank.acceur_status === "Activo")
                      .map((bank) => {
                        return bank.acceur_Bank ? (
                          <option value={bank.acceur_id} key={bank.acceur_id}>
                            {bank.acceur_Bank}
                          </option>
                        ) : null;
                      })
                  : payment === "USD"
                  ? banksUSD
                      .filter((bank) => bank.accusd_status === "Activo")
                      .map((bank) => {
                        return bank.accusd_Bank ? (
                          <option value={bank.accusd_id} key={bank.accusd_id}>
                            {bank.accusd_Bank}
                          </option>
                        ) : null;
                      })
                  : null}
              </Input>
            </FormGroup>
          )}

          {selectedMethod.startsWith("efectivo") && (
            <div className="efectivo-details">
              <FormGroup>
                <Label for="phoneInput" className="form-label">
                  Teléfono
                </Label>
                <Input
                  type="text"
                  id="phoneInput"
                  placeholder="Número de teléfono"
                  value={cashPhone}
                  onChange={(e) => setCashPhone(e.target.value)}
                  className="custom-input"
                />
              </FormGroup>
              <FormGroup>
                <Label for="codeInput" className="form-label">
                  Código de Transacción
                </Label>
                <Input
                  type="text"
                  id="codeInput"
                  placeholder="Código"
                  value={cash}
                  onChange={(e) => setCash(e.target.value)}
                  className="custom-input"
                />
              </FormGroup>
            </div>
          )}

          {bankOptionPay &&
            (payment === "EUR"
              ? banksEUR.map((bank) => {
                  return (
                    bank.acceur_id === parseInt(bankOptionPay) && (
                      <div className="iban-info" key={bank.acceur_id}>
                        <p>Número de cuenta: {bank.acceur_number}</p>
                        <p>CONCEPTO: {bank.acceur_swift}</p>
                      </div>
                    )
                  );
                })
              : payment === "USD"
              ? banksUSD.map((bank) => {
                  return bank.accusd_id === parseInt(bankOptionPay) ? (
                    <div className="iban-info" key={bank.accusd_id}>
                      <p>Número de cuenta: {bank.accusd_number}</p>
                      <p>Correo para Zelle: {bank.accusd_email}</p>
                      <p>CONCEPTO: {bank.accusd_phone}</p>
                    </div>
                  ) : null;
                })
              : null)}

          <FormGroup>
            <Label for="amountInput" className="form-label">
              Monto
            </Label>
            <Input
              type="number"
              id="amountInput"
              placeholder="Monto a transferir (debe ser superior a 20)"
              value={sendAmount}
              onChange={(e) => setSendAmount(e.target.value)}
              className="custom-input"
            />
          </FormGroup>

          <div className="form-navigation">
            <Button
              color="secondary"
              onClick={handlePreviousStep}
              className="nav-btn"
            >
              <FaArrowLeft /> Volver
            </Button>
            <Button
              color="primary"
              onClick={handleNextStep}
              className="nav-btn"
            >
              Continuar <FaArrowRight />
            </Button>
          </div>
        </div>
      </CSSTransition>

      {/* Paso 3: Adjuntar comprobante */}
      <CSSTransition
        in={step === 3}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <div className="step-content">
          <h3 className="step-header">Sube tu comprobante</h3>
          <Input
            type="file"
            onChange={handleFileChange}
            className="file-input"
          />
          {mov_img && (
            <img src={imageUrl} alt="Comprobante" className="preview-image" />
          )}

          <div className="form-navigation">
            <Button
              color="secondary"
              onClick={handlePreviousStep}
              className="nav-btn"
            >
              <FaArrowLeft /> Volver
            </Button>
            <Button
              color="success"
              onClick={handleSubmitLoad}
              className="nav-btn"
            >
              Confirmar <FaArrowRight />
            </Button>
          </div>
        </div>
      </CSSTransition>

      {/* Paso 4: Confirmación */}
      <CSSTransition
          in={step === 4}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div className="form-container-edit step-4">
            <h2>
              {transactionError ? "¡Ocurrió un problema!" : "¡Recarga Exitosa!"}
            </h2>
            <p>
              {transactionError
                ? "Hubo un error en la transacción. Por favor, intenta nuevamente más tarde."
                : "En breve se verá reflejado el estatus de tu transferencia."}
            </p>
            {transactionError ? (
              <FaTimesCircle size={50} color="#dc3545" />
            ) : (
              <FaCheckCircle size={50} color="#28a745" />
            )}

            <div className="form-actions">
              <button
                className="another-recharge-button"
                onClick={resetRecharge}
              >
                Realizar otra recarga
              </button>
              <button
                className="finish-button"
                onClick={() => (window.location.href = "/changes")}
              >
                Finalizar
              </button>
            </div>
          </div>
        </CSSTransition>
    </div>
  );
}

export { Recharge };