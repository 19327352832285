// Directory.js
import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { FaEdit, FaUserCheck, FaUserTimes } from "react-icons/fa";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { toast } from "react-toastify";
import EEUU from "../Assets/Images/EEUU.png";
import Peru from "../Assets/Images/Peru.png";
import Venezuela from "../Assets/Images/Venezuela.png";
import Panama from "../Assets/Images/Panama.png";
import Ecuador from "../Assets/Images/Ecuador.png";
import Colombia from "../Assets/Images/Colombia.png";
import Chile from "../Assets/Images/Chile.png";
import { banksByCountry } from "../Utils/Variables";
import { useAxiosInterceptors } from "../Hooks/useAxiosInterceptors";
import { useDataContext } from "../Context/dataContext";

function Directory() {
  useAxiosInterceptors();
  const { url, infoTkn } = useDataContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // DATOS PARA BENEFICIARIO
  const [accbsUser_bank, setAccbsUser_bank] = useState("");
  const [accbsUser_owner, setAccbsUser_owner] = useState("");
  const [accbsUser_number, setAccbsUser_number] = useState("");
  const [accbsUser_dni, setAccbsUser_dni] = useState("");
  const [accbsUser_phone, setAccbsUser_phone] = useState("");
  const [accbsUser_type, setAccbsUser_type] = useState("");
  const [accbsUser_country, setAccbsUser_country] = useState("");
  const [accbsUser_email, setAccbsUser_email] = useState("");

  // Estado para editar beneficiario
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);

  // Estado para validaciones
  const [errors, setErrors] = useState({});

  // Estado del usuario
  const [user, setUser] = useState([]);
  const [userDirectory, setUserDirectory] = useState([]);

  // Prefijos para teléfono
  // const [telefonoPrefix, setTelefonoPrefix] = useState("");

  // Alternar modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Fetch de datos del usuario
  const fetchDataUser = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/Auth/findByToken/${infoTkn}`, {
        headers: {
          Authorization: `Bearer ${infoTkn}`,
        },
      });
      setUser(response.data);

      const responseDirectory = await axios.get(
        `${url}/AccBsUser/user/${response.data.use_id}`,
        {
          headers: {
            Authorization: `Bearer ${infoTkn}`,
          },
        }
      );
      setUserDirectory(responseDirectory.data);
    } catch (error) {
      console.log(error);
    }
  }, [setUser, infoTkn, url]);

  // Validación de formulario
  const validateForm = () => {
    const newErrors = {};

    if (!accbsUser_country) {
      newErrors.accbsUser_country = "El país es requerido.";
    }

    if (!accbsUser_owner) {
      newErrors.accbsUser_owner = "El nombre es requerido.";
    }

    if (accbsUser_type === "Pago Movil") {
      const fullPhone = accbsUser_phone;
      if (!accbsUser_phone) {
        newErrors.accbsUser_phone = "El número telefónico es requerido.";
      } else if (!/^\d+$/.test(fullPhone)) {
        newErrors.accbsUser_phone =
          "El número telefónico solo puede contener dígitos.";
      }
      else if (accbsUser_phone<11 || accbsUser_phone>11) {
        newErrors.accbsUser_phone =
          "El número telefónico debe tener 11 dígitos.";
      }
    } else if (accbsUser_type === "Cuenta Bancaria" && !accbsUser_number) {
      newErrors.accbsUser_number = "El número de cuenta bancaria es requerido.";
    } else if (accbsUser_type === "Cuenta Bancaria" && (accbsUser_number < 20 || accbsUser_number > 20)) {
      newErrors.accbsUser_number = "El número de cuenta debe tener 20 dígitos.";
    } else if (
      accbsUser_type === "Zelle" &&
      (!accbsUser_email || accbsUser_country !== "Estados Unidos")
    ) {
      newErrors.accbsUser_email =
        "El correo electrónico es requerido para Zelle.";
    }

    if (!accbsUser_bank) {
      newErrors.accbsUser_bank = "El banco es requerido.";
    }

    setErrors(newErrors);
    console.log(errors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddAccountSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    // Si accbsUser_dni es nulo o vacío, asignar "NA"
    const finalDni = accbsUser_dni ? accbsUser_dni : "NA";

    try {
      await axios.post(
        `${url}/AccBsUser/create`,
        {
          accbsUser_bank,
          accbsUser_owner,
          accbsUser_number,
          accbsUser_dni: finalDni, // Usamos el DNI con "NA" si está vacío
          accbsUser_phone, // Aquí usamos el número concatenado
          accbsUser_type,
          accbsUser_status: "Activo",
          accbsUser_userId: user.use_id,
          accbsUser_country,
        },
        {
          headers: {
            Authorization: `Bearer ${infoTkn}`,
          },
        }
      );

      window.location.reload();

      toast.success("Cuenta agregada con éxito!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log("Error al agregar la cuenta:", error.response || error);
      toast.error("Error al agregar la cuenta", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    try {
      await axios.put(
        `${url}/AccBsUser/${selectedBeneficiary.accbsUser_id}`,
        {
          accbsUser_owner: accbsUser_owner
            ? accbsUser_owner
            : selectedBeneficiary.accbsUser_owner,
          accbsUser_bank: accbsUser_bank
            ? accbsUser_bank
            : selectedBeneficiary.accbsUser_bank,
          accbsUser_number: accbsUser_number
            ? accbsUser_number
            : selectedBeneficiary.accbsUser_number,
          accbsUser_dni: accbsUser_dni
            ? accbsUser_dni
            : selectedBeneficiary.accbsUser_dni,
          accbsUser_phone, // Aquí se concatena el prefijo y el número de teléfono
          accbsUser_type: accbsUser_type
            ? accbsUser_type
            : selectedBeneficiary.accbsUser_type,
        },
        {
          headers: {
            Authorization: `Bearer ${infoTkn}`,
          },
        }
      );

      closeEditModal();
      window.location.reload();

      toast.success("Beneficiario editado con éxito!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error("Error al editar el beneficiario", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleStatus = async (beneficiario) => {
    try {
      if (beneficiario.accbsUser_status === "Inactivo") {
        await axios.put(
          `${url}/AccBsUser/${beneficiario.accbsUser_id}`,
          {
            accbsUser_status: "Activo",
          },
          {
            headers: {
              Authorization: `Bearer ${infoTkn}`,
            },
          }
        );
      } else {
        await axios.put(
          `${url}/AccBsUser/${beneficiario.accbsUser_id}`,
          {
            accbsUser_status: "Inactivo",
          },
          {
            headers: {
              Authorization: `Bearer ${infoTkn}`,
            },
          }
        );
      }

      window.location.reload();

      toast.success("Cuenta desactivada con éxito!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error("Error al desactivar la cuenta", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const openEditModal = (beneficiary) => {
    setSelectedBeneficiary(beneficiary);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedBeneficiary(null);
    setIsEditModalOpen(false);
  };

  useEffect(() => {
    fetchDataUser();
  }, [fetchDataUser]);

  return (
    <div className="directory">
      <header className="directory__header">
        <h1>Tus Beneficiarios</h1>
        <Button color="primary" onClick={toggleModal}>
          Nuevo beneficiario
        </Button>
      </header>

      <div className="directory__list">
        {userDirectory.map((beneficiary) => (
          <div className="beneficiary-card" key={beneficiary.accbsUser_id}>
            <img
              src={
                beneficiary.accbsUser_country === "Venezuela"
                  ? Venezuela
                  : beneficiary.accbsUser_country === "Colombia"
                  ? Colombia
                  : beneficiary.accbsUser_country === "Chile"
                  ? Chile
                  : beneficiary.accbsUser_country === "Ecuador"
                  ? Ecuador
                  : beneficiary.accbsUser_country === "Peru"
                  ? Peru
                  : beneficiary.accbsUser_country === "Panama"
                  ? Panama
                  : beneficiary.accbsUser_country === "Estados Unidos"
                  ? EEUU
                  : null
              }
              alt={`${beneficiary.accbsUser_country} bandera`}
              className="country-flag"
            />
            <div className="beneficiary-info">
              <h3>{beneficiary.accbsUser_owner}</h3>
              <p>Cédula: {beneficiary.accbsUser_dni}</p>
              <p>Banco: {beneficiary.accbsUser_bank}</p>
              <p>Télefono: {beneficiary.accbsUser_phone}</p>
              <p>Tipo: {beneficiary.accbsUser_type}</p>
              <p>Status: {beneficiary.accbsUser_status}</p>
            </div>
            <div className="action-buttons">
              <Button
                className="btn-edit"
                onClick={() => openEditModal(beneficiary)}
              >
                <FaEdit /> Editar
              </Button>

              <Button
                className={
                  beneficiary.accbsUser_status === "Activo"
                    ? "btn-deactivate"
                    : "btn-activate"
                }
                onClick={() => {
                  handleStatus(beneficiary);
                }}
              >
                {beneficiary.accbsUser_status === "Activo" ? (
                  <FaUserTimes />
                ) : (
                  <FaUserCheck />
                )}
                {beneficiary.accbsUser_status === "Activo"
                  ? " Desactivar"
                  : " Activar"}
              </Button>
            </div>
          </div>
        ))}
      </div>

      {/* Modal para agregar nuevo beneficiario */}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Agregar Nuevo Beneficiario
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="country">País</Label>
              <Input
                type="select"
                name="country"
                id="country"
                value={accbsUser_country}
                onChange={(e) => setAccbsUser_country(e.target.value)}
                required
              >
                <option value="">Selecciona un país...</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Chile">Chile</option>
                <option value="Colombia">Colombia</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Panama">Panamá</option>
                <option value="Peru">Perú</option>
                <option value="Estados Unidos">EEUU</option>
              </Input>
              {errors.accbsUser_country && (
                <span className="error">{errors.accbsUser_country}</span>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="bank">Banco</Label>
              <Input
                type="select"
                name="bank"
                id="bank"
                value={accbsUser_bank}
                onChange={(e) => setAccbsUser_bank(e.target.value)}
                required
              >
                <option value="">Selecciona un banco...</option>
                {accbsUser_country &&
                  banksByCountry[accbsUser_country]?.map((bank, index) => (
                    <option key={index} value={bank}>
                      {bank}
                    </option>
                  ))}
              </Input>
              {errors.accbsUser_bank && (
                <span className="error">{errors.accbsUser_bank}</span>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="name">Nombre y Apellido</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={accbsUser_owner}
                onChange={(e) => setAccbsUser_owner(e.target.value)}
                placeholder="Ingresa nombre"
                required
              />
              {errors.accbsUser_owner && (
                <span className="error">{errors.accbsUser_owner}</span>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="dni">Cédula</Label>
              <Input
                type="text"
                name="dni"
                id="dni"
                value={accbsUser_dni}
                onChange={(e) => setAccbsUser_dni(e.target.value)}
                placeholder="Ingresa cédula"
                required
              />
              {errors.accbsUser_dni && (
                <span className="error">{errors.accbsUser_dni}</span>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="accountType">Tipo de Cuenta</Label>
              <Input
                type="select"
                name="accountType"
                id="accountType"
                value={accbsUser_type}
                onChange={(e) => setAccbsUser_type(e.target.value)}
                required
              >
                <option value="">Selecciona...</option>
                {accbsUser_country === "Venezuela" && (
                  <option value="Pago Movil">Pago Móvil</option>
                )}
                {accbsUser_country === "Estados Unidos" && (
                  <>
                    <option value="Cuenta Bancaria">Cuenta Bancaria</option>
                    <option value="Zelle">Zelle</option>
                  </>
                )}
                {accbsUser_country !== "Estados Unidos" && (
                  <option value="Cuenta Bancaria">Cuenta Bancaria</option>
                )}
              </Input>
              {errors.accbsUser_type && (
                <span className="error">{errors.accbsUser_type}</span>
              )}
            </FormGroup>

            {/* Mostrar campo correspondiente según el tipo de cuenta */}
            {accbsUser_type === "Pago Movil" && (
              <FormGroup>
                <Label for="phone">Teléfono</Label>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  value={accbsUser_phone}
                  onChange={(e) => setAccbsUser_phone(e.target.value)}
                  placeholder="Ingresa teléfono"
                  required
                />
                {errors.accbsUser_phone && (
                  <span className="error">{errors.accbsUser_phone}</span>
                )}
              </FormGroup>
            )}

            {accbsUser_type === "Cuenta Bancaria" && (
              <FormGroup>
                <Label for="number">Número de Cuenta Bancaria</Label>
                <Input
                  type="text"
                  name="number"
                  id="number"
                  value={accbsUser_number}
                  onChange={(e) => setAccbsUser_number(e.target.value)}
                  placeholder="Ingresa número de cuenta"
                  required
                />
                {errors.accbsUser_number && (
                  <span className="error">{errors.accbsUser_number}</span>
                )}
              </FormGroup>
            )}

            {accbsUser_type === "Zelle" &&
              accbsUser_country === "Estados Unidos" && (
                <FormGroup>
                  <Label for="email">Correo Electrónico</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={accbsUser_email}
                    onChange={(e) => setAccbsUser_email(e.target.value)}
                    placeholder="Ingresa correo electrónico"
                    required
                  />
                </FormGroup>
              )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddAccountSubmit}>
            Guardar Beneficiario
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal para editar beneficiario */}
      {isEditModalOpen && selectedBeneficiary && (
        <Modal isOpen={isEditModalOpen} toggle={closeEditModal}>
          <ModalHeader toggle={closeEditModal}>Editar Beneficiario</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="name">Nombre y Apellido</Label>
                <Input
                  type="text"
                  defaultValue={selectedBeneficiary.accbsUser_owner}
                  onChange={(e) => setAccbsUser_owner(e.target.value)}
                />
              </FormGroup>

              {selectedBeneficiary.accbsUser_country !== "Estados Unidos" && (
                <FormGroup>
                  <Label for="dni">Cédula</Label>
                  <Input
                    type="text"
                    defaultValue={selectedBeneficiary.accbsUser_dni}
                    onChange={(e) => setAccbsUser_dni(e.target.value)}
                    required
                  />
                </FormGroup>
              )}

              <FormGroup>
                <Label for="bank">Banco</Label>
                <Input
                  type="select"
                  value={accbsUser_bank || selectedBeneficiary.accbsUser_bank}
                  onChange={(e) => setAccbsUser_bank(e.target.value)}
                >
                  {banksByCountry[selectedBeneficiary.accbsUser_country]?.map(
                    (bank) => (
                      <option key={bank} value={bank}>
                        {bank}
                      </option>
                    )
                  )}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="accountType">Tipo de Cuenta</Label>
                <Input
                  type="select"
                  value={accbsUser_type || selectedBeneficiary.accbsUser_type}
                  onChange={(e) => setAccbsUser_type(e.target.value)}
                  required
                >
                  <option value="Pago Movil">Pago Móvil</option>
                  <option value="Cuenta Bancaria">Cuenta Bancaria</option>
                  {selectedBeneficiary.accbsUser_country ===
                    "Estados Unidos" && <option value="Zelle">Zelle</option>}
                </Input>
              </FormGroup>

              {/* Mostrar campo de teléfono o número de cuenta según el tipo de cuenta seleccionado */}
              {accbsUser_type === "Pago Movil" ||
              selectedBeneficiary.accbsUser_type === "Pago Movil" ? (
                <FormGroup>
                  <Label for="phone">Teléfono</Label>
                  <Input
                    type="text"
                    defaultValue={selectedBeneficiary.accbsUser_phone}
                    onChange={(e) => setAccbsUser_phone(e.target.value)}
                    required
                  />
                </FormGroup>
              ) : null}

              {accbsUser_type === "Cuenta Bancaria" ||
              selectedBeneficiary.accbsUser_type === "Cuenta Bancaria" ? (
                <FormGroup>
                  <Label for="number">Número de Cuenta Bancaria</Label>
                  <Input
                    type="text"
                    defaultValue={selectedBeneficiary.accbsUser_number}
                    onChange={(e) => setAccbsUser_number(e.target.value)}
                    required
                  />
                </FormGroup>
              ) : null}

              {accbsUser_type === "Zelle" &&
              selectedBeneficiary.accbsUser_country === "Estados Unidos" ? (
                <FormGroup>
                  <Label for="email">Correo Electrónico</Label>
                  <Input
                    type="email"
                    defaultValue={selectedBeneficiary.accbsUser_email}
                    onChange={(e) => setAccbsUser_email(e.target.value)}
                    required
                  />
                </FormGroup>
              ) : null}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleEdit}>
              Guardar Cambios
            </Button>
            <Button color="secondary" onClick={closeEditModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
}

export { Directory };
