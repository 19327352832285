import React from "react";

function TermsAndConditions() {
  return (
    <div className="privacy-policy">
      <div className="container">
        <h1 className="title">Términos y condiciones</h1>
        <span>
          Última actualización: 27 de Octubre de 2024
          <br />
          EuropaCambiosVE, S.L. (en adelante, “EuropaCambiosVE”)
        </span>

        <section className="section">
          <h2 className="section-title">1. Objeto del contrato</h2>
          <p style={{ textAlign: "justify" }}>
            Los presentes términos y condiciones (en adelante, “Términos”)
            regulan el acceso y uso de los servicios de cambio de divisas
            proporcionados por [EuropaCambiosVE S.L] (en adelante,
            “EuropaCambiosVE”), con domicilio en [Madrid], y sujeta a las leyes
            vigentes en España. El uso de nuestros servicios implica la
            aceptación plena y sin reservas de estos Términos por parte del
            usuario.
          </p>
        </section>

        <section className="section">
          <h2 className="section-title">2. Legislación aplicable</h2>
          <p style={{ textAlign: "justify" }}>
            La actividad de cambio de divisas de la Empresa se rige por la Ley
            10/2010 de 28 de abril, de prevención del blanqueo de capitales y de
            la financiación del terrorismo, el Real Decreto 304/2014, de 5 de
            mayo, y demás normativa de desarrollo que sea de aplicación.
          </p>
        </section>

        <section className="section">
          <h2 className="section-title">
            3. Identificación del cliente y proceso KYC (Conoce a tu Cliente)
          </h2>
          <p style={{ textAlign: "justify" }}>
            En cumplimiento de las obligaciones legales en materia de prevención
            del blanqueo de capitales y financiación del terrorismo, todos los
            clientes que deseen realizar operaciones de cambio de divisas
            deberán pasar por un proceso de verificación de identidad o “Conoce
            a tu Cliente” (KYC).
          </p>
          <p style={{ textAlign: "justify" }}>
            Para completar este proceso, el cliente deberá proporcionar la
            siguiente información y documentación:
          </p>
          <ul>
            <li>Nombre completo.</li>
            <li>Documento de identidad válido (DNI, pasaporte o NIE).</li>
            <li>
              Prueba de dirección (factura de servicios públicos o extracto
              bancario reciente).
            </li>
            <li>
              En algunos casos, según la cuantía y naturaleza de la operación,
              podrá ser solicitada información adicional, como la fuente de los
              fondos o justificante de ingresos
            </li>
          </ul>
          <p style={{ textAlign: "justify" }}>
            La Empresa se reserva el derecho de rechazar cualquier transacción
            si no se completa satisfactoriamente el proceso de verificación de
            identidad, o si se sospecha que los fondos están relacionados con
            actividades ilícitas.
          </p>
        </section>

        <section className="section">
          <h2 className="section-title">4. Protección de datos personales</h2>
          <p style={{ textAlign: "justify" }}>
            La Empresa recopila y trata los datos personales de los clientes de
            conformidad con el Reglamento General de Protección de Datos (RGPD)
            y la Ley Orgánica de Protección de Datos y Garantía de los Derechos
            Digitales (LOPDGDD). Los datos recopilados para el proceso KYC serán
            utilizados exclusivamente para cumplir con las obligaciones legales
            en materia de prevención del blanqueo de capitales y la financiación
            del terrorismo.
          </p>
          <br />
          <p style={{ textAlign: "justify" }}>
            Para más información sobre cómo tratamos sus datos personales,
            consulte nuestra <a href="/Privacy">[Política de Privacidad]</a>.
          </p>
        </section>

        <section className="section">
          <h2 className="section-title">5. Servicios de cambio de divisas</h2>
          <p style={{ textAlign: "justify" }}>
            La Empresa ofrece servicios de cambio de divisas a los tipos de
            cambio vigentes en el mercado en el momento de la operación. La
            Empresa no garantiza un tipo de cambio fijo y los tipos de cambio
            pueden variar hasta el momento en que se confirme la transacción.
          </p>
        </section>

        <section className="section">
          <h2 className="section-title">6. Comisiones y cargos</h2>
          <p style={{ textAlign: "justify" }}>
            La Empresa podrá aplicar comisiones y cargos por el uso de los
            servicios de cambio de divisas. Dichas comisiones serán notificadas
            al cliente antes de la confirmación de la transacción y se
            detallarán en el recibo de la operación.
          </p>
        </section>

        <section className="section">
          <h2 className="section-title">7. Limitaciones y restricciones</h2>
          <ul>
            <li>
              La Empresa se reserva el derecho de establecer límites a la
              cantidad de dinero que se puede cambiar en una sola operación o en
              un periodo de tiempo determinado.
            </li>
            <li>
              La Empresa se reserva el derecho de suspender o cancelar cualquier
              transacción si sospecha que la misma puede estar vinculada a
              actividades ilegales, incluyendo pero no limitándose al blanqueo
              de capitales o la financiación del terrorismo.
            </li>
          </ul>
        </section>

        <section className="section">
          <h2 className="section-title">8. Responsabilidades del cliente</h2>
          <p style={{ textAlign: "justify" }}>
            El cliente garantiza que los fondos utilizados en las transacciones
            de cambio de divisas son de origen lícito y que la transacción no
            viola ninguna ley aplicable. Asimismo, el cliente se compromete a
            proporcionar información veraz y completa en el proceso de
            verificación de identidad.
          </p>
        </section>

        <section className="section">
          <h2 className="section-title">9. Exención de responsabilidad</h2>
          <p style={{ textAlign: "justify" }}>
            La Empresa no será responsable por los daños y perjuicios que puedan
            derivarse de la suspensión o cancelación de una operación debido a
            la falta de cumplimiento del cliente con el proceso de verificación
            de identidad (KYC) o a la detección de actividades sospechosas.
            Además, la Empresa no se hace responsable de las variaciones en los
            tipos de cambio que ocurran antes de la confirmación de la
            transacción.
          </p>
        </section>

        <section className="section">
          <h2 className="section-title">10. Modificación de los Términos</h2>
          <p style={{ textAlign: "justify" }}>
            La Empresa se reserva el derecho de modificar estos Términos en
            cualquier momento. Las modificaciones serán publicadas en nuestra
            página web y, en su caso, notificadas a los clientes de forma
            adecuada. El uso continuado de los servicios después de la
            publicación de las modificaciones implicará la aceptación de los
            nuevos Términos.
          </p>
        </section>

        <section className="section">
          <h2 className="section-title">11. Jurisdicción y resolución de conflictos</h2>
          <p style={{ textAlign: "justify" }}>
          Cualquier disputa que surja en relación con los servicios de cambio de divisas será sometida a la jurisdicción de los tribunales de [Madrid], España, y se regirá por la legislación española aplicable.
          </p>
        </section>

        <section className="section">
          <h2 className="section-title">12. Contacto</h2>
          <p style={{ textAlign: "justify" }}>
          <strong>Si tiene alguna pregunta o duda sobre estos Términos, puede
            contactarnos a través de nuestro WhatsApp +34 624377261 o nuestro correo electrónico
            <a href="mailto:Servicio@europacambiosve.com"> Servicio@europacambiosve.com</a> </strong> 
            
           <strong> Nuestro único dominio es <a href="https://europacambiosve.com/">https://europacambiosve.com/</a>. 
            Tenga en cuenta que estos son los únicos medios de contacto oficiales. EuropaCambiosVE no se
            responsabiliza por transacciones o comunicaciones fuera de estos
            canales oficiales. </strong>
          </p>
        </section>
        
      </div>
    </div>
  );
}

export { TermsAndConditions };
