import React, { useState, useEffect, useCallback } from "react";
import { FaEye } from "react-icons/fa";
import { useDataContext } from "../Context/dataContext";
import axios from "axios";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
} from "reactstrap";
import { useAxiosInterceptors } from "../Hooks/useAxiosInterceptors";

function Movements() {
  useAxiosInterceptors();
  const { infoTkn, url } = useDataContext();
  const [activeTab, setActiveTab] = useState("all");
  const [userMovements, setUserMovements] = useState([]);
  const [selectedMovement, setSelectedMovement] = useState(null);

  const handleTabChange = (tab) => setActiveTab(tab);

  const fetchDataUser = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/Auth/findByToken/${infoTkn}`, {
        headers: {
          Authorization: `Bearer ${infoTkn}`,
        },
      });

      const responseMovements = await axios.get(
        `${url}/Movements/user/${response.data.use_id}`,
        {
          headers: {
            Authorization: `Bearer ${infoTkn}`,
          },
        }
      );
      setUserMovements(responseMovements.data);
      console.log(responseMovements.data);
    } catch (error) {
      console.log(error);
    }
  }, [infoTkn, url]);

  const filteredTransactions = userMovements.filter((transaction) => {
    if (activeTab === "all") return true;
    return transaction.mov_status === activeTab;
  });

  useEffect(() => {
    fetchDataUser();
  }, [fetchDataUser]);

  const showDetails = (transaction) => setSelectedMovement(transaction);
  const closeDetails = () => setSelectedMovement(null);

  return (
    <div className="movements">
      <div className="movements__tabs">
        <button
          className={`tab ${activeTab === "all" ? "active" : ""}`}
          onClick={() => handleTabChange("all")}
        >
          Todas
        </button>
        <button
          className={`tab ${activeTab === "V" ? "active" : ""}`}
          onClick={() => handleTabChange("V")}
        >
          Aprobada
        </button>
        <button
          className={`tab ${activeTab === "R" ? "active" : ""}`}
          onClick={() => handleTabChange("R")}
        >
          Rechazada
        </button>
        <button
          className={`tab ${activeTab === "E" ? "active" : ""}`}
          onClick={() => handleTabChange("E")}
        >
          En espera
        </button>
      </div>

      <div className="table-responsive">
        <Table bordered responsive className="movements__table">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Tipo</th>
              <th>Monto</th>
              <th>Estado</th>
              <th>Detalles</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions.reverse().map((transaction) => (
              <tr key={transaction.mov_id}>
                <td>{transaction.mov_date}</td>
                <td>{transaction.mov_type}</td>
                <td>
                  <span>
                    {" "}
                    {transaction.mov_amount} {transaction.mov_currency}
                  </span>
                </td>
                <td
                  className={`status-${transaction.mov_status.toLowerCase()}`}
                >
                  {transaction.mov_status === "E"
                    ? "En espera"
                    : transaction.mov_status === "V"
                    ? "Aprobada"
                    : "Rechazada"}
                </td>
                <td>
                  <FaEye
                    className="view-details-icon"
                    onClick={() => showDetails(transaction)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {selectedMovement && (
        <Modal isOpen={!!selectedMovement} toggle={closeDetails}>
          <ModalHeader toggle={closeDetails}>
            Detalles del Movimiento
          </ModalHeader>
          <ModalBody>
            <p>
              <strong>Fecha:</strong> {selectedMovement.mov_date}
            </p>
            <p>
              <strong>Monto:</strong> {selectedMovement.mov_amount}{" "}
              {selectedMovement.mov_currency}
            </p>
            <p>
              <strong>Estado:</strong>{" "}
              {selectedMovement.mov_status === "E"
                ? "En espera"
                : selectedMovement.mov_status === "V"
                ? "Aprobada"
                : "Rechazada"}
            </p>

            {selectedMovement.AccountsBsUser &&
            selectedMovement.mov_typeOutflow === "transferencia" ? (
              <>
                <p>
                  <strong>Banco:</strong>{" "}
                  {selectedMovement.AccountsBsUser.accbsUser_bank}
                </p>
                <p>
                  <strong>Propietario:</strong>{" "}
                  {selectedMovement.AccountsBsUser.accbsUser_owner}
                </p>
                <p>
                  <strong>Número de cuenta o Pago Móvil:</strong>{" "}
                  {selectedMovement.AccountsBsUser.accbsUser_number ||
                    selectedMovement.AccountsBsUser.accbsUser_phone}
                </p>
                <p>
                  <strong>DNI:</strong>{" "}
                  {selectedMovement.AccountsBsUser.accbsUser_dni}
                </p>
              </>
            ) : (
              <p
                dangerouslySetInnerHTML={{
                  __html: selectedMovement.mov_comment
                    ? selectedMovement.mov_comment.replace(/\n/g, "<br/>")
                    : "Sin comentarios disponibles.",
                }}
              />
            )}

            {/* Mostrar imagen si está disponible */}
            {selectedMovement.mov_img &&
              !selectedMovement.mov_img.toLowerCase().includes(".pdf") && (
                <img
                  className="modal-image"
                  alt="ImageMovement"
                  src={`${url}/Movements/image/${selectedMovement.mov_img}`}
                />
              )}
            {!selectedMovement.mov_img && <p>No hay documento adjunto.</p>}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={closeDetails}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
}

export { Movements };
